<template>
  <div>
    <modal ref="modal">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">{{ headerLabel }}</h2>
      </div>
      <div class="uk-modal-body" uk-overflow-auto>
        <CustomerData :customer="customer" v-model="customer" />
      </div>
      <div class="uk-modal-footer">
        <div class="uk-text-right">
          <button class="uk-button uk-button-primary uk-border-rounded" type="submit" :disabled="sending" @click.prevent="handleSubmit">Speichern</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import CustomerData from './CustomerData'
import CustomerService from '@/services/customer.service'
import TechnicianService from '@/services/technician.service'
import InsuranceService from '@/services/insurance.service.js'
export default {
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  components: {
    Modal,
    CustomerData,
  },
  data() {
    return {
      sending: false,
      customer: {
        id: null,
        company: '',
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        asp: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        fax: '',
        mobile: '',
      },
    }
  },
  computed: {
    headerLabel() {
      let label = ''
      switch (this.type) {
        case 'customer':
          label = 'Kunde'
          break
        case 'technician':
          label = 'Messtechniker'
          break
        case 'insuranceContact':
          label = 'Versicherung'
          break
      }

      return label
    },
  },
  methods: {
    open() {
      this.$refs.modal.open()
    },
    close() {
      this.$refs.modal.close()
    },
    async handleSubmit() {
      let response = null
      if (this.type === 'customer') {
        response = await CustomerService.store(this.customer)
      } else if (this.type === 'insuranceContact') {
        response = await InsuranceService.store(this.customer)
      } else {
        response = await TechnicianService.store(this.customer)
      }

      this.$emit('created', response.data)
      this.resetCustomer()
    },
    resetCustomer() {
      this.customer = {
        id: null,
        company: '',
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        asp: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        fax: '',
        mobile: '',
      }
    },
  },
}
</script>
